import './toolCode.css';
import { CopyOutlined } from '@ant-design/icons';
import { python } from '@codemirror/lang-python';
import { Clipboard } from '@nextgis/utils';
import { quietlight } from '@uiw/codemirror-theme-quietlight';
import CodeMirror from '@uiw/react-codemirror';
import { EditorView } from '@uiw/react-codemirror';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ToolCode: React.FC<{ code: string }> = ({ code }) => {
  const { t } = useTranslation();
  const [copyText, setCopyText] = useState(t('operation.copyTT'));

  return (
    <div className="code-snippet ">
      <div style={{ whiteSpace: 'pre-wrap', position: 'relative' }}>
        <Tooltip title={copyText}>
          <Button
            className="copy-code-button"
            onBlur={() => {
              setCopyText(t('operation.copyTT'));
            }}
            onClick={() => {
              Clipboard.copy(code || '');
              setTimeout(() => {
                setCopyText(t('operation.copiedTT'));
              }, 100);
            }}
            type="primary"
            icon={
              <CopyOutlined style={{ marginTop: '2px', marginRight: '2px' }} />
            }
          />
        </Tooltip>
        <CodeMirror
          style={{
            borderRadius: '10px',
            overflow: 'hidden',
          }}
          theme={quietlight}
          value={code}
          editable={false}
          extensions={[python(), EditorView.lineWrapping]}
          autoFocus={false}
          basicSetup={{
            highlightActiveLine: false,
            highlightActiveLineGutter: false,
          }}
        />
      </div>
    </div>
  );
};

export { ToolCode };
