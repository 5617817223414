import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { toolBoxStore } from '../store/store';

const NotFound: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://${window.location.host}/404`} />
      </Helmet>

      <div
        style={{
          height: '100%',
          paddingTop: '40px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            maxWidth: '1100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1
            style={{
              margin: 0,
              fontSize: 180,
              textAlign: 'center',
              color: '#176fc1',
            }}
          >
            404
          </h1>
          <h2 style={{ marginBottom: 0, fontSize: 28, textAlign: 'center' }}>
            {t('notFound.notFound')}
          </h2>
          {toolBoxStore.deployMode !== 'onpremise' && (
            <p style={{ fontSize: 16 }}>
              {t('notFound.checkURL')}{' '}
              <a href="mailto:info@nextgis.com">{t('notFound.supportLink')}</a>
            </p>
          )}
          <a href="/">
            <Button
              shape="round"
              style={{
                marginTop: '10px',
                width: '160px',
                height: '52px',
                backgroundColor: '#176fc1',
                color: 'white',
                fontSize: 18,
                boxShadow: '0 4px 6px rgba(0,0,0,.12)',
              }}
            >
              {t('notFound.toMain')}
            </Button>
          </a>
        </div>
      </div>
    </>
  );
});

export { NotFound };
